<template>
<div class="cip-content">
    <div class="cip-content-header-card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('customerdoc.myDownloads') }}
        </div>
    </div>

    <div class="card" v-for="sec of sections" :key="sec.id">
        <div class="card-header slim-fit">
            <button type="button" class="btn cip-card-header-btn btn-light btn-block collapsed" data-toggle="collapse" :data-target="'#sec' + sec.id"
                    @click="setDocs(sec.id)">
                <span class="cip-card-header-btn-text">{{ sec.sectionName }}</span>
                <div class="float-right">
                    <font-awesome-icon icon="chevron-down" class="rotating-chevron"/>
                </div>
            </button>
        </div>
        <div class="card-body collapse" :id="'sec' + sec.id">
            <div class="row" v-for="doc of docs[sec.id]" :key="doc.id">
                <!--div class="col-md-4">
                    <span class="form-control-plaintext">{{ doc.customer == null ? '' : doc.customer.customerName }}</span>
                </div-->
                <div class="offset-md-1 col-md-3">
                    <span class="form-control-plaintext">{{ doc.fileDescr }}</span>
                </div>
                <div class="offset-md-1 col-md-4">
                    <span class="form-control-plaintext">{{ doc.fileName }}</span>
                </div>
                <div class="col-md-1">
                    <a class="btn btn-light" :href="getCustomerDocURL(doc.id)">
                        <font-awesome-icon icon="file-alt" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import cipAxios from '@/cipAxios'
import { getUrl } from '@/cipLib'

export default Vue.extend({
    name: 'customerDoc',

    data () {
        return {
            sections: [],
            docs: [],   // list of list of customer-docs. index of 
            errorMsg: null,
            timer: null,
        }
    },

    created: function() {
        this.setSections()
        //.then(() => this.setDocs())
    },

    methods: {
        setSections() {
            return cipAxios.get('rest/cusdocsec')
            .then(response => {
                this.sections = response.data
            })
        },

        setDocs(secId) {
            return cipAxios.get('rest/cusdoc', {
                params: {
                    sectionId: secId,
                }
            })
            .then(response => {
                //this.docs[secId] = response.data
                this.$set(this.docs, secId, response.data)
            })
            .catch((errorMsg) => {
                this.errorMsg = errorMsg
            })
        },

        /*setDocsWithTimeout(secId) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.setDocs(secId)
            }, 500);
        },*/

        getCustomerDocURL(customerDocId) {
            return getUrl('rest/cusdoc/' + customerDocId + '/file')
        },

    }
})
</script>
