<template>
<div class="cip-content">
    <div class="full-frame-card">
        <iframe id="iframe" :src="src" ref="iframe"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import { getUrl } from '../../cipLib'

export default {
    name: 's-days-in-stock',
    mixins: [CipIFrame],
    data () {
        return {
            src: '',
        }
    },

    beforeRouteUpdate() {
        this.$refs.iframe.contentWindow.location.replace(this.src)
    },

    methods: {
        setSrc() {
            this.src = getUrl('Main?NAVPOINT=S_CustStock_OL&MODULE=REP&REPNAME=S_CustStock_OL&ACTION=PARAMEXPERT&SORT1=DAYS_IN_STOCK&SORT2=CUSTOMER_GROUP_NAME&SORT3=CUSTOMER_NAME&SORT4=MILL_NAME&SORT5=ORDER_NO&SORT6=ORDER_POS_NO&COLUMN1=DAYS_IN_STOCK')
        }
    }
}
</script>

