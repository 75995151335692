<template>
<div id="certificate" class="cip-content">

    <div class="card">
        <div class="card-body">
            <form @submit.prevent="findCertificates(true)">
                <div class="form-group row">
                    <label for="mill" class="col-2 col-form-label">{{ $t('mill.name') }}</label>
                    <div class="col-2">
                        <select v-model="millId" name="mill" class="form-control" @change="findCertificates(true)">
                            <option></option>
                            <option v-for="mill of mills" :value="mill.millId" :key="mill.millId">{{ mill.millName }}</option>
                        </select>
                    </div>
                    <label for="customer" class="col-2 col-form-label" v-if="useCustomerLov || useCustomerAutoComplete">{{ $t('customer.name') }}</label>
                    <div class="col-5">
                        <select v-model="customerId" name="customer" class="form-control" @change="findCertificates(true)" v-if="useCustomerLov">
                            <option></option>
                            <option v-for="customer of customers" :value="customer.customerId" :key="customer.customerId">
                                {{ customer.customerNo }} - {{ customer.customerName }}
                            </option>
                        </select>
                        <!-- TODO:
                            skip customer-filter, event to trigger findCertificates ?
                            minMatchingCars = 0 support -> https://github.com/alexurquhart/vue-bootstrap-typeahead/pull/47
                            support for arrow keys to select -> https://github.com/alexurquhart/vue-bootstrap-typeahead/pull/44, ...
                            reset customerNameFilter not reactive(?) -> https://github.com/alexurquhart/vue-bootstrap-typeahead/issues/19
                        -->
                        <vue-bootstrap-typeahead name="customer" v-if="useCustomerAutoComplete"
                                v-model="customerNameFilter"
                                :minMatchingChars = 1
                                :maxMatches = 20
                                :data="customers"
                                :serializer="c => c.customerNo + ' ' + c.customerName"
                                @hit="customerId = $event.customerId; findCertificates(true)"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="textFilter" class="col-2 col-form-label">{{ $t('label.textsearch') }}</label>
                    <div class="col-6">
                        <input type="text" name="textFilter" class="form-control" v-model="textFilter" @input="findCertificatesWithTimeout()">
                    </div>
                    <!--div class="col-1">
                        <button type="button" class="btn btn-primary">Search</button>
                    </div-->
                    <!--div class="col-1">
                        <button type="button" class="btn btn-secondary" @click="resetFilter()">Reset</button>
                    </div-->
                </div>
            </form>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <table class="table" v-if="certs.length > 0">
                <thead>
                <tr>
                    <th>{{ $t('customer.name') }}</th>
                    <th>{{ $t('customer.grade') }}</th>
                    <th>{{ $t('grade.name') }}</th>
                    <th>{{ $t('mill.name') }}</th>
                    <th>{{ $t('order.orderNoPosNo') }}</th>
                    <th>{{ $t('order.productionOrderNo') }}</th>
                    <th>{{ $t('certificate.no') }}</th>
                    <th>{{ $t('certificate.date') }}</th>
                    <th>PDF</th>
                    <th>Excel</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="cert in certs" :key="cert.id">
                    <td>{{ cert.customer.customerShortName }}</td>
                    <td>
                        <ul><li v-for="grade in cert.grades" :key="grade"> {{ grade }}</li></ul>
                    </td>
                    <td>
                      <ul><li v-for="decorGradeName in cert.decorGradeNames" :key="decorGradeName"> {{ decorGradeName }}</li></ul>
                    </td>
                    <td>{{ cert.mill.millName }}</td>
                    <td>
                        <ul><li v-for="orderPosKey in cert.orderPosKeys" :key="orderPosKey"> {{ orderPosKey }}</li></ul>
                    </td>
                    <td>{{ cert.productionOrderNo }}</td>
                    <td>{{ cert.certificateNo }}</td>
                    <td>{{ cert.certificateDateTime | cipFormatDateTime }}</td>
                    <td>
                        <a class="btn btn-light" :href="getCertificateFileURL(cert.id)" v-if="cert.hasCertificateFile">
                            <font-awesome-icon icon="file-pdf"/>
                        </a>
                    </td>
                    <td>
                        <a class="btn btn-light" :href="getCertificateExcelURL(cert.id)">
                            <font-awesome-icon icon="file-excel"/>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>

            <div v-if="nextCertCursor != null" class="row justify-content-md-center">
                <div class="col-md-2">
                    <button type="button" class="btn btn-light" @click="findCertificates(false)">{{ this.$t('label.loadMore') }}</button>
                </div>
            </div>

            <div v-if="certs.length == 0">
                {{ $t('certificate.notfound') }}
            </div>
        </div>
    </div>

</div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
//import cipAxios from '@/cipAxios'
import cipGraphQLClient from '@/cipGraphQLClient'
import { getUrl } from '@/cipLib'

export default {
    name: 'certificates',
    components: {
        VueBootstrapTypeahead,
    },
    data() {
        return {
            millId: null,
            mills: [],
            customerId: null,
            customerNameFilter: '',
            customers: [],
            textFilter: '',
            customerTimer: null,
            useCustomerLov: false,
            useCustomerAutoComplete: false,
            certificateNo: null,
            orderNo: null,
            orderPosNo: null,
            certs: [],
            nextCertCursor: null,
            certTimer: null,
        }
    },
    computed: {
        /*user() {
            return this.$store.state.user
        },*/
        businessArea: {
            get() {
                return this.$store.state.businessArea
            },
            set(businessArea) {
                this.$store.commit('setBusinessArea', businessArea)
            },
        }
    },
    watch: {
        'customerNameFilter': function() {
            this.findCustomersWithTimeout()
        },
        businessArea(/*newBusinessArea, oldBusinessArea*/) {
            //console.log("certificate-form: businessArea changed to " + newBusinessArea + " old = " + oldBusinessArea)

            // TODO problems with email-link: new page is constructed, businessArea is reset to null
            // but important for F5
            // if (oldBusinessArea != null) {
                this.initFormData()
            //}
        }
    },
    created: function() {
        //console.log('routes: ', this.$route.params)
        if (typeof this.$route.params.millId !== 'undefined')
            this.millId = this.$route.params.millId
        if (typeof this.$route.params.certificateNo !== 'undefined') {
            this.certificateNo = this.$route.params.certificateNo
            this.textFilter = this.$route.params.certificateNo
        }
        if (typeof this.$route.params.orderNo !== 'undefined') {
            this.orderNo = this.$route.params.orderNo
            this.textFilter = this.$route.params.orderNo
        }
        if (typeof this.$route.params.orderPosNo !== 'undefined')
            this.orderPosNo = parseInt(this.$route.params.orderPosNo)

        if (this.businessArea != null) {
            this.initFormData()
        }
    },

    methods: {
        initFormData() {
            this.findCertificates(true)
            .then(this.initFilters())
        },

        initFilters() {
            const maxLovCustomers = 40
            const query =
                `query filters($businessAreaCode: String!) {
                    millsOfCertificates(businessAreaCode: $businessAreaCode) {
                        millId
                        millName
                    }
                    customerCntOfCertificates(businessAreaCode: $businessAreaCode)
                }`
            return cipGraphQLClient.request(query , {
                businessAreaCode: this.businessArea,
            })
            .then(data => {
                //console.log(data)   // TODO handle queryQL error
                this.mills = data.millsOfCertificates
                if (data.customerCntOfCertificates <= maxLovCustomers) {
                    this.useCustomerLov = true
                    this.useCustomerAutoComplete = false
                    this.findCustomers(maxLovCustomers)
                }
                else {
                    this.useCustomerLov = false
                    this.useCustomerAutoComplete = true
                    this.customers = []
                }
            })
        },

        findCustomers(maxCustomers) {
            const query =
                `query filters($businessAreaCode: String!, $customerNameFilter: String!, $maxCustomers: Int) {
                    customersOfCertificates(businessAreaCode: $businessAreaCode, customerNameFilter: $customerNameFilter, maxElements: $maxCustomers) {
                        customerId
                        customerNo
                        customerName
                    }
                }`
            return cipGraphQLClient.request(query , {
                businessAreaCode: this.businessArea,
                customerNameFilter: this.customerNameFilter,
                maxCustomers: maxCustomers == "undefined" || maxCustomers == null ? 100 : maxCustomers
            })
            .then(data => {
                this.customers = data.customersOfCertificates
            })
        },

        findCustomersWithTimeout() {
            //console.log('findCustomersWithTimeout')
            if (this.customerTimer) {
                clearTimeout(this.customerTimer);
                this.customerTimer = null;
            }
            this.customerTimer = setTimeout(() => {
                this.findCustomers()
            }, 500);
        },

        findCertificates(firstPage) {
            let businessAreaChangeable = this.certificateNo != null || this.orderNo != null  // ignore and reset selected business-area if certificate or order is searched for
            const query =
                `query filters($millId: Int, $certificateNo: String, $customerId: Int, $businessAreaCode: String, $orderNo: String, $orderPosNo: Int,
                                 $textFilter: String, $pageSize: Int, $nextCursor: Cursor) {
                    certificates(millId: $millId, certificateNo: $certificateNo, customerId: $customerId, businessAreaCode: $businessAreaCode,
                                orderNo: $orderNo, orderPosNo: $orderPosNo, textFilter: $textFilter, pageSize: $pageSize, nextCursor: $nextCursor) {
                        page {
                            id
                            mill {
                                millId
                                millCode
                                millName
                            }
                            certificateNo
                            certificateDateTime
                            customer {
                                customerId
                                customerNo
                                customerName
                                customerShortName
                                businessAreaCode
                            }
                            productionOrderNo
                            productLineColorRed
                            productLineColorGreen
                            productLineColorBlue
                            orderPosKeys
                            customerOrderNos
                            grades
                            decorGradeNames
                            hasCertificateFile
                            changeDate
                        },
                        nextCursor
                    }
                }`
            return cipGraphQLClient.request(query , {
                millId: this.millId == "" ? null : this.millId,
                certificateNo: this.certificateNo,
                customerId: this.customerId == "" ? null : this.customerId,
                businessAreaCode: businessAreaChangeable ? null : this.businessArea,
                orderNo: this.orderNo,
                orderPosNo: this.orderPosNo,
                textFilter: this.textFilter == null ? null : this.textFilter.trim(),
                pageSize: 40,
                nextCursor: firstPage ? null : this.nextCertCursor
            })
            .then(data => {
                this.certs = firstPage ? data.certificates.page : this.certs.concat(data.certificates.page)
                this.nextCertCursor = data.certificates.nextCursor
                //console.log('getting certificates: #', this.certs.length)
                if (businessAreaChangeable && this.certs.length >= 1) {
                    //console.log('setting business area = ' + this.certs[0].customer.businessAreaCode)
                    this.businessArea = this.certs[0].customer.businessAreaCode
                    this.certificateNo = null
                    this.orderNo = null
                    this.orderPosNo = null
                }
            })
        },

        /* TODO https://medium.com/vuejs-tips/v-debounce-c382064a395d */
        findCertificatesWithTimeout() {
            //console.log('findCertificatesWithTimeout')
            if (this.certTimer) {
                clearTimeout(this.certTimer);
                this.certTimer = null;
            }
            this.certTimer = setTimeout(() => {
                this.findCertificates(true)
            }, 500);
        },

        getCertificateFileURL(certId) {
            return getUrl('rest/cert/' + certId + '/file')
        },

        getCertificateExcelURL(certId) {
            return getUrl('rest/cert/' + certId + '/excel')
        },

        resetFilter() {
            this.millId = null
            this.certificateNo = null
            this.orderNo = null
            this.orderPosNo = null
            this.customerId = null
            this.customerNameFilter = ''
            this.customers = []
            this.textFilter = ''
        }

    }
}
</script>

<style lang="scss" scoped>
ul {
    list-style-type: none;
    padding-left: 0px;
}
div .card {
    min-width: 1300px;
}
</style>
