<template>
<div id="news" class="cip-content">

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="btn-group col-3" role="group">
                    <button type="button" class="btn btn-secondary" v-bind:class="{ active: status == 'unreadOnly' }"
                            @click="setUnreadNewsList()">
                        {{ $t('news.unread') }}
                    </button>
                    <button type="button" class="btn btn-secondary" v-bind:class="{ active: status == 'all' }"
                            @click="setAllNewsList()">
                        {{ $t('news.all') }}
                    </button>
                </div>
                <div class="btn-group col-2" role="group">
                    <button type="button" class="btn btn-secondary" v-bind:class="{ active: status == 'unpublishedAlso' }"
                            @click="setUnpublishedNewsList()" v-if="isNewsAdmin()">
                        {{ $t('news.unpublishedAlso') }}
                    </button>
                </div>
                <div>
                    <spinner class="col-3" :status="newsAreLoading" :size="35" :speed="1">
                        <!--:color="spinnerColor" -->
                    </spinner>
                </div>
            </div>
        </div>
    </div>

    <div class="card" v-if="newsList.size == 0">
        <div class="card-body">
            {{ $t('news.notfound') }}
        </div>
    </div>

    <div class="card" v-for="news in newsList" :key="news.id">
        <router-link :to="'/news/' + news.id" class="btn" id="dtllnk">
            <div class="container-fluid m-0 p-0">
                <div class="row">
                    <div class="col-9 header-line-text" :class="{unread: !news.readByUser, unpublished: !news.published}">
                        <span v-if="news.text4User != null">{{ news.text4User.headerText }}</span>
                    </div>
                    <!-- if="news.fileAttached" -->
                    <!--div class="col-1">
                        <font-awesome-icon icon="file-alt"/>
                    </div-->
                    <div class="col-2 header-line-text">
                        {{ news.publishingDate | cipFormatDate }}
                    </div>
                </div>
            </div>
        </router-link>
    </div>

    <div class="card" v-if="nextCursor != null">
        <div class="card-body">
            <div class="row justify-content-md-center">
                <div class="col-md-2">
                    <button type="button" class="btn btn-light" @click="setNewsList(false)">{{ this.$t('label.loadMore') }}</button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import Spinner from 'vue-spinner-component/src/Spinner.vue'
import cipGraphQLClient from '@/cipGraphQLClient'
import { handleHttpError } from '@/cipAxios'
import * as cipDateLib from '../../cipDateLib'

export default {
    name: 'news',
    components: {
        Spinner
    },
    data () {
        return {
            status: 'all', //'unreadOnly', 'unpublishedAlso'
            newsAreLoading: false,
            newsList: [],
            nextCursor: null
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    created: function() {
        this.setNewsList(true)
    },
    methods: {
        setNewsList(firstPage) {
            this.newsAreLoading = true
            const query =
                `query newsList($atDateTime: LocalDateTime!, $unreadOnly: Boolean!, $unpublishedAlso: Boolean, $pageSize: Int, $nextCursor: Cursor) {
                    newsList(atDateTime: $atDateTime, unreadOnly: $unreadOnly, unpublishedAlso: $unpublishedAlso, pageSize: $pageSize, nextCursor: $nextCursor) {
                        page {
                            id
                            publishingDate
                            text4User {
                                langCode
                                headerText
                            }
                            readByUser
                            published
                        },
                        nextCursor
                    }
                }`
            cipGraphQLClient.request(query , {
                atDateTime: cipDateLib.createLocalDateTime(new Date()),
                unreadOnly: this.status == 'unreadOnly',
                unpublishedAlso: this.status == 'unpublishedAlso',
                pageSize: 20,
                nextCursor: firstPage ? null : this.nextCursor
            })
            .then(data => {
                this.newsList = firstPage ? data.newsList.page : this.newsList.concat(data.newsList.page)
                this.nextCursor = data.newsList.nextCursor
                this.newsAreLoading = false
            })
            .catch(error => {
                handleHttpError(error.response.status, error.response.error)
            })
        },

        setAllNewsList() {
            this.status = 'all'
            this.setNewsList(true)
        },

        setUnreadNewsList() {
            this.status = 'unreadOnly'
            this.setNewsList(true)
        },

        setUnpublishedNewsList() {
            this.status = 'unpublishedAlso'
            this.setNewsList(true)
        },

        isNewsAdmin() {
            return this.user != null && this.user.privs.find(priv => priv === 'NEWS.EDIT') !== undefined
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../variables';

#dtllnk {
  padding-left: 20px;
}

.header-line-text {
    padding-top: 8px;
    padding-bottom: 8px;
}

.unread {
    font-weight: bold;
}

.unpublished {
    font-style: italic;
    background-color: $color-light-grey;
}
</style>
