<template>
<div id="oldorderhistory" class="cip-content">
    <div class="cip-content-header-card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('order.oldHistory')}}
        </div>
    </div>
      
    <div class="card container">
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>{{ $t('order.pos.prod') }} {{ $t('customer.businessArea.decor') }}</th>
                <th>{{ $t('order.prod') }} {{ $t('customer.businessArea.e2p') }}</th>
                <th>{{ $t('order.pos.prod') }} {{ $t('customer.businessArea.e2p') }}</th>
            </tr>
            </thead>
        
            <tbody>
                <tr>
                    <td>Unterkochen</td>
                    <td><a :href="buildOldOrderHistoryFileUrl('Produced_Order_Positions_Unterkochen_2019_02_28_v2.xlsx')">
                            28.02.2019
                        </a>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Tolosa</td>
                    <td><a :href="buildOldOrderHistoryFileUrl('Produced_Order_Positions_Tolosa_2018_12_01_v2.xlsx')">
                            01.12.2018
                        </a>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Arches</td>
                    <!--td><a :href="buildOldOrderHistoryFileUrl('Produced_Orders_D_Arches_2019_05_31_1100.xlsx')">
                            31.05.2019
                        </a>
                    </td-->
                    <td><a :href="buildOldOrderHistoryFileUrl('Produced_Order_Positions_D_Arches_2019_05_31_1100.xlsx')">
                            31.05.2019
                        </a>
                    </td>
                    <td><a :href="buildOldOrderHistoryFileUrl('Produced_Orders_E_Arches_2019_05_31_1100.xlsx')">
                            31.05.2019
                        </a>
                    </td>
                    <td><a :href="buildOldOrderHistoryFileUrl('Produced_Order_Positions_E_Arches_2019_05_31_1100.xlsx')">
                            31.05.2019
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>Dettingen</td>
                    <td><a :href="buildOldOrderHistoryFileUrl('Produced_Order_Positions_D_Dettingen_2019_11_01.xlsx')">
                            01.11.2019
                        </a>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<script>
import { getUrl } from '../../cipLib'

export default {
    name: 'old-order-history',
    data () {
        return {
        }
    },

    methods: {
        buildOldOrderHistoryFileUrl(fileName) {
            return getUrl('rest/oldorderhistory/' + fileName)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../variables';

div .card, .card-header {
    //min-width: 1300px;
}

a {
    //padding-left: 10px;
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 300;
}

a:hover {
    text-decoration: none;
    color: $color-orange;
}
</style>

