<template>
<div class="cip-content">
    <div class="full-frame-card">
        <iframe id="iframe" :src="src" ref="iframe"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import auth from '../../auth'
import { getUrl } from '../../cipLib'

export default {
    name: 'open-item',
    mixins: [CipIFrame],
    data () {
        return {
            src: '',
        }
    },

    beforeRouteUpdate() {
        this.$refs.iframe.contentWindow.location.replace(this.src)
    },

    methods: {
        setSrc(user) {
            if (auth.hasPriv(user, 'Open_Item_CG'))
                this.src = getUrl('Main?NAVPOINT=Open_Item_CG&MODULE=REP&REPNAME=Open_Item_CG&ACTION=PARAMEXPERT')
            else if (auth.hasPriv(user, 'Open_Item_C_M'))
                this.src = getUrl('Main?NAVPOINT=Open_Item_C_M&MODULE=REP&REPNAME=Open_Item_C_M&ACTION=PARAMEXPERT')
            else
                this.src = getUrl('Main?NAVPOINT=Open_Item_D&MODULE=REP&REPNAME=Open_Item_D&ACTION=PARAMEXPERT')
        }
    }
}
</script>
