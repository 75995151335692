<template>
<ul class="navbar-nav ml-auto">
    <li v-for="topMenu in menu" :text="topMenu.name" :key="topMenu.code" class="dropdown">
        <a href="#" class="top-menu dropdown-toggle" data-toggle="dropdown">{{ topMenu.name }}</a>
        <ul class="dropdown-menu">
            <li v-for="menuEntry in topMenu.visibleMenuEntries(user, businessArea)" :key="menuEntry.path">
                <router-link :to="menuEntry.path" class="second-menu" v-if="menuEntry.isSimplePath()">{{ menuEntry.name }}</router-link>
                <a href="#" @click="menuEntry.navigate()" class="second-menu" v-else>{{ menuEntry.name }}</a>
            </li>
        </ul>
    </li>
</ul>
</template>

<script>
import auth from '../auth'

class TopMenu {
    constructor(code, name, privs, menuEntries) {
        this.code = code
        this.name = name
        this.privs = privs
        this.menuEntries = menuEntries
    }

    isVisible(user, businessArea) {
        if (this.privs != null)
            return auth.isUserAuthorizedForAnyPriv(user, this.privs)
        else {
            for (let me of this.menuEntries) {
                if (me.isVisible(user, businessArea))
                    return true
            }
            return false
        }
    }

    visibleMenuEntries(user, businessArea) {
        return this.menuEntries.filter(me => me.isVisible(user, businessArea))
    }
}

class MenuEntry {
    constructor(name, path, add_random, router, busArea) {
        this.name = name
        this.path = path
        this.add_random = add_random
        this.router = router
        if (router == null) {
            // if router is empty then path is handled as a normal link, no privs required
            this.privs = null
        }
        else {
            this.privs = []
            const route = router.options.routes.find(r => r.path.startsWith(path))
            if (route != null) {
                this.privs = route.meta.privs
            }
        }
        this.busArea = busArea
    }

    isVisible(user, businessArea) {
        if (this.busArea != null && this.busArea != businessArea) {
            return false
        }
        return auth.isUserAuthorizedForAnyPriv(user, this.privs)
    }

    // internal link without 'random' parameter
    isSimplePath() {
        return this.router != null && !this.add_random
    }

    navigate() {
        if (this.router != null) {
            let xtpath = this.path
            if (this.add_random) {
                // TODO leads to "blocked" routes in some cases
                xtpath += '/' + Math.floor(Math.random() * 10000000)
            }
            //console.log(this.name + ': going to ' + xtpath)
            this.router.push(xtpath)
        }
        else {
            window.open(this.path,'_blank')
        }
    }
}

export default {
    name: 'cip-menu',
    inject: ['eventBus'],
    data () {
        return {
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        businessArea() {
            return this.$store.state.businessArea
        },
        menu: function() {
            return this.buildMenuTree().filter(tm => tm.isVisible(this.user, this.businessArea))
        }
    },
    methods: {
        buildMenuTree() {
            return [
                new TopMenu('ORDER', this.$t('order.menu'), null, [
                    new MenuEntry(this.$t('order.nonProd'), '/dnonproducedorder', false, this.$router, 'D'),
                    new MenuEntry(this.$t('order.nonProd'), '/enonproducedorder', false, this.$router, 'E'),
                    new MenuEntry(this.$t('order.nonProd'), '/tnonproducedorder', false, this.$router, 'T'),
                    new MenuEntry(this.$t('order.nonProd'), '/snonproducedorder', false, this.$router, 'S'),
                    new MenuEntry(this.$t('order.nonProd'), '/pnonproducedorder', false, this.$router, 'P'),
                    new MenuEntry(this.$t('order.open'), '/copenorder', false, this.$router, 'C'),
                    new MenuEntry(this.$t('order.open'), '/eopenorder', false, this.$router, 'E'),
                    new MenuEntry(this.$t('order.open'), '/topenorder', false, this.$router, 'T'),
                    new MenuEntry(this.$t('order.openStock'), '/topenorderstock', false, this.$router, 'T'),
                    new MenuEntry(this.$t('order.open'), '/sopenorder', false, this.$router, 'S'),
                    new MenuEntry(this.$t('order.prod'), '/dproducedorder', false, this.$router, 'D'),
                    new MenuEntry(this.$t('order.prod'), '/eproducedorder', false, this.$router, 'E'),
                    new MenuEntry(this.$t('order.prod'), '/tproducedorder', false, this.$router, 'T'),
                    new MenuEntry(this.$t('order.prod'), '/sproducedorder', false, this.$router, 'S'),
                    new MenuEntry(this.$t('order.prod'), '/pproducedorder', false, this.$router, 'P'),
                    new MenuEntry(this.$t('order.oldHistory'), '/oldorderhistory', false, this.$router, null),
                ]),
                new TopMenu('STOCK', this.$t('stock.menu'), null, [
                    new MenuEntry(this.$t('stock.stock'), '/dstock', false, this.$router, 'D'),
                    new MenuEntry(this.$t('stock.stock'), '/cstock', false, this.$router, 'C'),
                    new MenuEntry(this.$t('stock.stock'), '/estock', false, this.$router, 'E'),
                    new MenuEntry(this.$t('stock.stock'), '/tstock', false, this.$router, 'T'),
                    new MenuEntry(this.$t('stock.stock'), '/sstock', false, this.$router, 'S'),
                    new MenuEntry(this.$t('stock.stock'), '/pstock', false, this.$router, 'P'),
                    new MenuEntry(this.$t('stock.daysInStock'), '/ddaysinstock', false, this.$router, 'D'),
                    new MenuEntry(this.$t('stock.daysInStock'), '/cdaysinstock', false, this.$router, 'C'),
                    new MenuEntry(this.$t('stock.daysInStock'), '/edaysinstock', false, this.$router, 'E'),
                    new MenuEntry(this.$t('stock.daysInStock'), '/tdaysinstock', false, this.$router, 'T'),
                    new MenuEntry(this.$t('stock.daysInStock'), '/sdaysinstock', false, this.$router, 'S'),
                    new MenuEntry(this.$t('stock.daysInStock'), '/pdaysinstock', false, this.$router, 'P'),
                ]),
                new TopMenu('CALLOFF', this.$t('calloff.menu'), null, [
                    new MenuEntry(this.$t('calloff.basket.menu'), '/calloff/basket', false, this.$router, null),
                    new MenuEntry(this.$t('calloff.archive.menu'), '/calloff/archive', false, this.$router, null),
                ]),
                new TopMenu('DISPATCH', this.$t('dispatch.menu'), null, [
                    new MenuEntry(this.$t('dispatch.dispatch'), '/dispatch', false, this.$router, null),
                ]),
                new TopMenu('INVOICES', this.$t('payables.menu'), null, [
                    new MenuEntry(this.$t('openitem.menu'), '/openitem', false, this.$router, null),
                    new MenuEntry(this.$t('invoice.menu'), '/invoice', false, this.$router, null),
                ]),
                new TopMenu('QUALITY', this.$t('certificate.menu'), null, [
                    new MenuEntry(this.$t('certificate.certificate'), '/certificate', false, this.$router, null),
                ]),
                new TopMenu('INFO', this.$t('info.menu'), ['MENU.UPDATE'], [
                    new MenuEntry(this.$t('ifile.lastLoading'), '/lastloading', false, this.$router, null),
                    new MenuEntry(this.$t('news.myNews'), '/news', false, this.$router, null),  // 'S'
                    new MenuEntry(this.$t('customerdoc.myDownloads'), '/docs', false, this.$router, 'S'),
                    new MenuEntry(this.$t('info.help'), 'staticHTML/help/index.htm', false, null, 'D'),
                    new MenuEntry(this.$t('info.help'), 'staticHTML/help/index.htm', false, null, 'E'),
                    new MenuEntry(this.$t('info.help'), 'staticHTML/help/index.htm', false, null, 'T'),
                    new MenuEntry(this.$t('info.help'), 'staticHTML/AM_CIP_MANUAL_2023_01_10.pdf', false, null, 'S'),
                    new MenuEntry(this.$t('info.businessterms'), 'files/terms/agb.html', false, null, 'D'),
                    new MenuEntry(this.$t('info.businessterms'), 'files/terms/agb.html', false, null, 'E'),
                    new MenuEntry(this.$t('info.businessterms'), 'files/terms/agb.html', false, null, 'T'),
                    new MenuEntry(this.$t('info.businessterms'), 'staticHTML/CIP_Business_Terms_2023_01_10.pdf', false, null, 'S'),
                ]),
                new TopMenu('ADMIN', this.$t('admin-menu'), null, [
                    new MenuEntry(this.$t('user.admin'), '/admin/user', false, this.$router, null),
                    new MenuEntry(this.$t('user.changeLog'), '/admin/userlog', false, this.$router, null),
                    new MenuEntry(this.$t('news.admin.menu'), '/admin/news', false, this.$router, null),
                    new MenuEntry(this.$t('customerdoc.section.admin.menu'), '/admin/cusdocsec', false, this.$router, null),
                    new MenuEntry(this.$t('customerdoc.admin.menu'), '/admin/cusdoc', false, this.$router, null),
                    new MenuEntry(this.$t('ifile.loadingLog'), '/admin/loadinglog', false, this.$router, null),
                    new MenuEntry(this.$t('openitem.admin.menu'), '/admin/openitem', false, this.$router, null),
                    new MenuEntry(this.$t('systemadmin.resourceOverview'), '/admin/resoverview', false, this.$router, null),
                    new MenuEntry(this.$t('systemadmin.schedulerJobList'), '/admin/schedjoblist', false, this.$router, null),
                ]),
            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import '../variables';

.top-menu {
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;
    color: $color-dark-grey;
}

/*.top-menu:focus {
    color: $color-orange;
    outline: none;
}*/

.second-menu {
    padding-left: 10px;
    color: $color-dark-grey;
    font-size: 14px;
    font-weight: 300;
}

a:hover {
    text-decoration: none;
    color: $color-orange;
}

// add menu show-on-hover
.dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown-menu {
    width: 230px;
}

// disable menu show-on-click (overriding bootstrap)
.dropdown-menu.show {
    display: none;
}

// override bootstrap caret
.dropdown-toggle::after {
    border: none !important;
}
</style>
