import { GraphQLClient } from 'graphql-request'
//import qs from 'qs'
//import router from './routerConfig'

const prefix = process.env.VUE_APP_BACKEND_URL == '' ? '' : process.env.VUE_APP_BACKEND_URL + '/'
const cipGraphQLClient = new GraphQLClient(prefix + "graphql", {
    credentials: 'include',
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }
})


/*
axios.create({
  
    baseURL: process.env.VUE_APP_BACKEND_URL,

    // to make cross-site calls work, used in development only
    withCredentials: true,

    paramsSerializer: function (params) {
        //console.log('serialize', params)
        return qs.stringify(params, {
            arrayFormat: 'repeat'
        })
    },

    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }

    //timeout: 3000,

})
*/

/*
cipAxios.interceptors.response.use(undefined, error => {
    let errorMsg = ''
    if (error.response) {
        //console.log('errors in interceptor', error.response)
        // TODO no redirect to login if already in login form
        if (error.response.status == 401 || error.response.status == 403) {
            if (error.response.data != 'CHANGE_PASSWORD' &&
                error.response.data != 'INVALID_LOGIN' &&
                error.response.data != 'USER_LOCKED_DUE_TO_INACTIVITY') {
                // typically "web session lost" => redirect to login
                router.push('/login')
            }
            else {
                errorMsg = error.response.data
            }
        }
        else if (error.response.status < 500) {
            errorMsg = error.response.data
        }
        else {
            errorMsg = "Error occured: " + error  // TODO?
        }
    }
    else if (error.request) {
        errorMsg = "Timeout to/from Server"
    }

    return Promise.reject(errorMsg)
})
*/


export default cipGraphQLClient;