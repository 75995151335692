function getUrl(relativeUrl) {
  let prefix = (process.env.VUE_APP_BACKEND_URL == '' ? '' : process.env.VUE_APP_BACKEND_URL + '/')
  return prefix + relativeUrl
}

function doCheckAmSeparation() {
  return Boolean(process.env.VUE_APP_CHECK_AM_SEPARATION)
}

function getCipMunksjoUrl() {
  return process.env.VUE_APP_CIP_MUNKSJO_URL
}

function isCipMunksjoUrl(url) {
  let url_check = process.env.VUE_APP_CIP_MUNKSJO_URL_CHECK
  return url != null && url.includes(url_check);
}

function getCipAhlstromUrl() {
  return process.env.VUE_APP_CIP_AHLSTROM_URL
}

function isCipAhlstromUrl(url) {
  //return !isCipMunksjoUrl(url) // DEV
  let url_check = process.env.VUE_APP_CIP_AHLSTROM_URL_CHECK
  return url != null && (url.includes(url_check));
}


export {getUrl, doCheckAmSeparation, isCipMunksjoUrl, getCipMunksjoUrl, isCipAhlstromUrl, getCipAhlstromUrl}
