<script>

export default {
    name: 'cip-iframe',
    data () {
        return {
            src: '',
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    /*watch: {
        user() {
            console.log('setting source for user')
            this.setSrc(this.user)
        }
    },*/
    created: function() {
        this.setSrc(this.user)
    },
    methods: {
        // for override
        // eslint-disable-next-line
        setSrc(user) {
            this.src = ''
        }
    }
}
</script>
