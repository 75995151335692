import Vue from 'vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/js/bootstrap.min.js'
//import 'bootstrap/dist/css/bootstrap.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faNewspaper, faFileAlt, faArrowsAltH, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleDown,
        faCog, faChartBar, faCircle, faCircleNotch, faEye, faEyeSlash, faTimes, faEdit, faSave, faTrashAlt, faFileContract, faFilter,
        faFilePdf, faFileExcel, faMinus, faPlus, faExchangeAlt, faChevronDown, faSortUp, faSortDown
    } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as cipDateLib from './cipDateLib'
import router from './routerConfig'
import i18n from './i18n'
import './validation'
import store from './vuex-store'
import App from './App.vue'

// turn off console "production tip" in DEV
Vue.config.productionTip = false

// fontawesome
library.add(faSignOutAlt, faNewspaper, faFileAlt, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleDown, faArrowsAltH,
    faChartBar, faCog, faCircle, faCircleNotch, faEye, faEyeSlash, faTimes, faEdit, faSave, faTrashAlt, faFileContract, faFilter,
    faFilePdf, faFileExcel, faMinus, faPlus, faExchangeAlt, faChevronDown, faSortUp, faSortDown)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// router
Vue.use(VueRouter)

// add global date filter
Vue.filter('cipFormatDate', cipDateLib.cipFormatDateFilter)
Vue.filter('cipFormatDateTime', cipDateLib.cipFormatDateTimeFilter)

new Vue({
    render: h => h(App),
    router,
    i18n,
    store,
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    /*
    methods: {
        getUser() {
            // $root references to 'this' but $rootComponent (=App, =highest VueComponent instance) is required
            return this.$children[0].user
        }
    }*/
}).$mount('#app')
