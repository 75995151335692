<template>
<div class="cip-content">
    <div class="full-frame-card">
        <iframe id="iframe" :src="src"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import { getUrl } from '../../cipLib'

export default {
  name: 'calloff-basket',
  mixins: [CipIFrame],
  data () {
    return {
      src: '',
    }
  },

  methods: {
    setSrc() {
      this.src = getUrl('calloffarchivefilter.form?resetFilter=true')
    }
  }
}
</script>

<style scoped lang="scss">
</style>