import cipAxios from './cipAxios'

export default {
  login(loginName, password) {
    let data = "LOGIN_NAME=" + loginName + "&PASSWORDB64=" + btoa(unescape(encodeURIComponent(password)))
    return cipAxios.post(
      'Main?NAVPOINT=PERSONALISATION.LOGIN&MODULE=PERSONALISATION&ACTION=LOGIN&REST=1', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
  },

  getLoggedInUser() {
    return cipAxios.get('rest/loggedinuser')
      .then(response => {
        if (typeof response.data == 'object') {
          return response.data
        } else {
          // if no user logged-in then empty string is returned (status 200, not an error!)
          return null
        }
      })
  },

  changePwd(loginName, currPassword, newPassword) {
    return cipAxios.post('rest/mypassword', {
      loginName: loginName,
      currPassword: currPassword,
      newPassword: newPassword
    })
  },

  changePwdFCS(loginName, currPassword, newPassword, newPassword2) {
    let data = encodeURI("LOGIN_NAME=" + loginName + "&PASSWORD_ORG=" + currPassword + "&PASSWORD1=" + newPassword + "&PASSWORD2=" + newPassword2)
    return cipAxios.post('Main?NAVPOINT=PERSONALISATION.CHANGEPASSWORD&MODULE=PERSONALISATION&ACTION=CHANGEPASSWORD&REST=1', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },

  logout() {
    return cipAxios.post(
      'Main?NAVPOINT=PERSONALISATION.LOGOUT&MODULE=PERSONALISATION&ACTION=LOGOUT&REST=1', '')
  },

  hasPriv(user, priv) {
    if (user == null)
      return false
    return user.privs.includes(priv)
  },

  hasAnyPriv(user, privs) {
    if (privs == null)
      return false
    for (let priv of privs) {
      if (user.privs.includes(priv))
        return true;
    }
    return false
  },

  isUserAuthorizedForAnyPriv(user, privs) {
    if (privs == null)
      // no privs required, anonymous access also allowed
      return true
    else if (user == null || privs.length == 0)
      return false
    else
      return this.hasAnyPriv(user, privs)
  },

  buildVisBusinessAreas(user, component) {
    function userHasBusinessArea(user, busArea) {
      return user != null && user.visBusinessAreaCodes.find(ba => ba === busArea) != undefined
    }

    // setting list of visible business areas
    let hasD = userHasBusinessArea(user, 'D')
    let hasC = userHasBusinessArea(user, 'C')
    let hasE = userHasBusinessArea(user, 'E')
    let hasT = userHasBusinessArea(user, 'T')
    let hasS = userHasBusinessArea(user, 'S')
    let hasF = userHasBusinessArea(user, 'F')
    let hasP = userHasBusinessArea(user, 'P')

    let bas = []
    if (hasD) {
      bas.push({code: 'D', name: component.$t('customer.businessArea.decor')})
    }
    if (hasC) {
      bas.push({code: 'C', name: component.$t('customer.businessArea.amdecor')})
    }
    if (hasE) {
      bas.push({code: 'E', name: component.$t('customer.businessArea.e2p')})
    }
    if (hasT) {
      bas.push({code: 'T', name: component.$t('customer.businessArea.tpp')})
    }
    if (hasS || hasF) {
      bas.push({
        code: 'S', name:
          (hasS ? component.$t('customer.businessArea.abrasive') : '') +
          (hasS && hasF ? '/' : '') +
          (hasF ? component.$t('customer.businessArea.fapp') : '')
      })
    }
    if (hasP) {
      bas.push({code: 'P', name: component.$t('customer.businessArea.fd')})
    }
    return bas
  },

  isUserAuthorizedForBusinessArea(user, busArea) {
    return user != null && user.visBusinessAreaCodes.find(ba => ba === busArea) != undefined
  },

  isUserAuthorizedForDecorTPP(user) {
    return this.isUserAuthorizedForBusinessArea(user, 'D') || this.isUserAuthorizedForBusinessArea(user, 'T')
  },

}
