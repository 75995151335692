<template>
    <iframe id="iframe" :src="src"></iframe> 
</template>

<script>
import CipIFrame from '../CipIFrame'
import { getUrl } from '../../cipLib'

export default {
  name: 'abo-configuration',
  mixins: [CipIFrame],
  data () {
    return {
      src: '',
    }
  },

  methods: {
    // eslint-disable-next-line
    setSrc(user) {
      this.src = getUrl('Main?NAVPOINT=PERSONALISATION.QUICKMENUCONFIG&MODULE=PERSONALISATION&ACTION=QUICKMENUCONFIG&TITLE=Configuration')
    }
  }
}
</script>

