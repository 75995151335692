import axios from 'axios'
import qs from 'qs'
import router from './routerConfig'

const cipAxios = axios.create({
  
    baseURL: process.env.VUE_APP_BACKEND_URL,

    // to make cross-site calls work, used in development only
    withCredentials: true,

    paramsSerializer: function (params) {
        //console.log('serialize', params)
        return qs.stringify(params, {
            arrayFormat: 'repeat'
        })
    },

    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }

    //timeout: 3000,

})

cipAxios.interceptors.response.use(undefined, error => {
    let errorMsg = ''
    if (error.response) {
        //console.log('errors in interceptor', error.response)
        // TODO no redirect to login if already in login form
        errorMsg = handleHttpError(error.response.status, error.response.data)
    }
    else if (error.request) {
        errorMsg = "Timeout to/from Server"
    }

    return Promise.reject(errorMsg)
})

export function handleHttpError(status, responseData) {
    if (status == 401 || status == 403) {
        if (responseData != 'CHANGE_PASSWORD' &&
            responseData != 'INVALID_LOGIN' &&
            responseData != 'USER_LOCKED_DUE_TO_INACTIVITY') {
            // typically "web session lost" => redirect to login
            router.push('/login')
            return ''
        }
        else {
            return responseData
        }
    }
    else if (status < 500) {
        return responseData
    }
    else {
        return "Error occured: " + status + " - " + responseData
    }
}

export default cipAxios
