<template>
    <div class="card">
        <div class="card-header slim-fit">
            <button type="button" class="btn cip-card-header-btn btn-light btn-block collapsed" data-toggle="collapse"
                    data-target="#myaccnt"
                    :disabled="!isSectionToggleable()" @click="toggleSection($options.name)">
                <span class="cip-card-header-btn-text">{{ $t('user.settings.myAccount') }}</span>
                <div class="float-right">
                    <font-awesome-icon icon="chevron-down" class="rotating-chevron"/>
                </div>
            </button>
        </div>
        <div class="card-body collapse" id="myaccnt">
            <form @submit.prevent="saveAccount">

                <div class="form-group form-row">
                    <label for="loginName" class="col-sm-2 col-form-label">{{ $t('user.loginName') }}</label>
                    <div class="col-sm-3">
                        <!--input name="loginName" type="text" class="form-control" :value="user.loginName" readonly-->
                        <span class="form-control-plaintext">{{ user.loginName }}</span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="salutation" class="col-sm-2 col-form-label">{{ $t('user.salutation') }}</label>
                    <div class="col-sm-3">
                        <input id="salutation" name="salutation" type="text"
                               v-model="user.salutation"
                               class="form-control"
                               v-if="editMode">
                        <span class="form-control-plaintext" v-else>{{ user.salutation }}</span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="title" class="col-sm-2 col-form-label">{{ $t('user.title') }}</label>
                    <div class="col-sm-3">
                        <input id="title" name="title" type="text"
                               class="form-control"
                               v-model="user.title"
                               v-if="editMode">
                        <span class="form-control-plaintext" v-else>{{ user.title }}</span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="firstName" class="col-sm-2 col-form-label">{{ $t('user.firstName') }}</label>
                    <div class="col-sm-6">
                        <input id="firstName" name="firstName" type="text"
                               v-model="v$.user.firstName.$model"
                               class="form-control" :class="{ 'invalid': v$.user.firstName.$error }"
                               v-if="editMode">
                        <span class="form-control-plaintext" v-else>{{ user.firstName }}</span>
                    </div>
                    <div class="col-sm-4">
                        <span class="form-text error-msg" v-if="v$.user.firstName.$error">
                            {{ v$.user.firstName.$errors[0].$message }}
                        </span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="lastName" class="col-sm-2 col-form-label">{{ $t('user.lastName') }}</label>
                    <div class="col-sm-6">
                        <input id="lastName" name="lastName" type="text"
                               v-model="v$.user.lastName.$model"
                               class="form-control" :class="{ 'invalid': v$.user.lastName.$error }"
                               v-if="editMode">
                        <span class="form-control-plaintext" v-else>{{ user.lastName }}</span>
                    </div>
                    <div class="col-sm-4">
                        <span class="form-text error-msg" v-if="v$.user.lastName.$error">
                            {{ v$.user.lastName.$errors[0].$message }}
                        </span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="company" class="col-sm-2 col-form-label">{{ $t('user.company') }}</label>
                    <div class="col-sm-6">
                        <input id="company" name="company" type="text"
                               v-model="user.companyName"
                               class="form-control"
                               v-if="editMode">
                        <span class="form-control-plaintext" v-else>{{ user.companyName }}</span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="email" class="col-sm-2 col-form-label">{{ $t('user.email') }}</label>
                    <div class="col-sm-6">
                        <input id="email" name="email" type="email"
                               v-model="v$.user.email.$model"
                               class="form-control" :class="{ 'invalid': v$.user.email.$error }"
                               v-if="editMode">
                        <span class="form-control-plaintext" v-else>{{ user.email }}</span>
                    </div>
                    <div class="col-sm-4">
                        <span class="form-text error-msg" v-if="v$.user.email.$error">
                            {{ v$.user.email.$errors[0].$message }}
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-2">
                        <button type="submit" class="btn btn-primary" v-if="editMode" :disabled="v$.$invalid">
                            {{ $t('label.save') }}
                        </button>
                    </div>
                    <div class="col-sm-2">
                        <button type="reset" class="btn btn-secondary" v-if="editMode" @click="resetAccountForm()">
                            {{ $t('label.reset') }}
                        </button>
                    </div>
                </div>
            </form>

            <div class="row">
                <div class="col-sm-3">
                    <button class="btn btn-secondary" v-if="!editMode" :disabled="!isSectionToggleable()"
                            @click="enterEditMode">
                        {{ $t('label.edit') }}
                    </button>
                </div>
                <div class="col-sm-9">
                    <span :class="{'error-msg': isErrorMsg}">{{ msg }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import auth from '@/auth'
import cipAxios from '@/cipAxios'
import useVuelidate from "@vuelidate/core"
import {required, email} from "@/validation"

export default Vue.extend({
    name: 'myAccount',
    props: ['lockedSection'],

    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            user: {
                loginName: "",
                salutation: "",
                title: "",
                firstName: "",
                lastName: "",
                email: "",
                companyName: "",
            },
            isOpen: false,
            msg: "",
            isErrorMsg: false,
        }
    },
    validations() {
        return {
            user: {
                firstName: {required},
                lastName: {required},
                email: {required, email}
            }
        }
    },
    computed: {
        editMode() {
            return this.lockedSection != null && this.lockedSection == this.$options.name
        }
    },

    created: function () {
        this.setUser()
    },

    methods: {
        setUser() {
            return auth.getLoggedInUser()
                .then(user => {
                    this.user = user
                    if (user == null) {
                        // most probably session lost: communicate to App.vue TODO redirect to login screen??
                        this.$emit('user-logged-out')
                        this.$router.push('login')
                    }
                })
        },

        isSectionToggleable() {
            return this.lockedSection == null// || this.lockedSection == this.$options.name
        },
        toggleSection(/*section*/) {
            this.isOpen = !this.isOpen
        },
        enterEditMode() {
            this.$parent.lockedSection = this.$options.name
            this.clearMsg()
        },
        leaveEditMode() {
            this.$parent.lockedSection = null
        },

        async saveAccount() {
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                cipAxios.post('rest/myaccount/' + this.user.userId, this.user)
                    .then(() => {
                        this.leaveEditMode()
                        return this.setUser()
                    })
                    .then(() => {
                        this.$emit('user-account-updated')
                        this.setInfoMsg("Account changed")
                    })
                    .catch((errorMsg) => {
                        this.errorMsg = errorMsg
                    })
            }
        },

        clearMsg() {
            this.msg = ""
            this.isErrorMsg = false
        },
        setInfoMsg(msg) {
            this.msg = msg
            this.isErrorMsg = false
        },
        setErrorMsg(msg) {
            this.msg = msg
            this.isErrorMsg = true
        },

        resetAccountForm() {
            this.setUser()
            this.v$.$reset()
            this.leaveEditMode()
        },
    }
})
</script>

<style lang="scss">
</style>
