import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        businessArea: null,     // selected business-area
    },
    /*getters: {
        func: (state) => (para) => {
            return ...
        }
    },*/
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setBusinessArea(state, businessArea) {
            state.businessArea = businessArea
        },
    },
})
