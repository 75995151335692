<template>
  <div class="cip-content">
    <div class="card" v-if="mode == 'login'">
      <div class="card-body">
        <form @submit.prevent="login" class="body-content">

          <div class="form-group row">
            <label for="loginName" class="col-sm-2 col-form-label">{{ $t('user.loginName') }}</label>
            <div class="col-sm-3">
              <input id="loginName" type="text" class="form-control" v-model="loginName">
            </div>
          </div>

          <div class="form-group row">
            <label for="loginName" class="col-sm-2 col-form-label">{{ $t('user.password') }}</label>
            <div class="col-sm-3">
              <input id="password" type="password" class="form-control" v-model="password">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2">
              <button type="submit" class="btn btn-primary">{{ $t('user.login') }}</button>
            </div>
            <div class="col">
              <p class="error-msg">{{ errorMsg }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>

    <changePassword :lockedSection="lockedSection" :loginName="loginName" :initiallyOpen="true"
                    v-if="mode == 'changePwd'"
                    @password-changed="newPassword => doLogin(loginName, newPassword)"/>

    <div class="card" v-if="mode == 'versionRefresh'">
      <div class="card-body">
        {{ $t('user.version.refresh', {version: this.version}) }}
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../auth'
import cipAxios from '../cipAxios'
import ChangePassword from './settings/ChangePassword.vue'
import semver from 'semver'

export default {
  name: 'login',
  inject: ['eventBus'],
  components: {
    ChangePassword
  },
  data() {
    return {
      loginName: '',
      password: '',
      errorMsg: '',
      mode: 'login', //'changePwd', 'versionRefresh'
      lockedSection: null,  // for use with changePwd component only
      version: ''
    }
  },

  methods: {
    login() {
      this.doLogin(this.loginName, this.password)
    },

    doLogin(loginName, password) {
      auth.login(loginName, password)
        .then(() => this.checkVersion())
        .then((versionOk) => {
          if (versionOk) {
            this.eventBus.$emit('user-logged-in')
            this.$router.push(this.$route.query.redirect || '/dashboard')
          } else {
            this.mode = 'versionRefresh'
          }
        })
        .catch((errorMsg) => {
          if (errorMsg == 'CHANGE_PASSWORD') {
            this.mode = 'changePwd'
          } else {
            this.errorMsg = errorMsg
          }
        })
    },

    checkVersion() {
      return cipAxios.get('rest/version')
        .then(response => {
          this.version = response.data
          let versionRange = process.env.VUE_APP_COMPATIBLE_VERSION_RANGE
          if (this.version != '' && typeof (versionRange) != 'undefined')
            return semver.satisfies(this.version, versionRange)
          else
            return true
        })
    }
  }
}
</script>
