<template>
  <div class="cip-content">
    <div class="card" style="min-width: 1500px">
      <div class="card-body">
        <div class="row" v-if="isCipMunksjoUrl() || businessArea == 'D' || businessArea == 'T'">
          <div class="col-md-12 text-center">
            <img src="@/assets/Welcome_Munksjo.jpg" class="rounded">
          </div>
        </div>

        <div class="row" v-else-if="extndBusinessArea == 'C' || extndBusinessArea == 'E' || extndBusinessArea == 'P'">
          <div class="col-md-12 text-center">
            <img src="@/assets/Decor_Terra_Nostra.jpg" class="rounded">
            <!--img src="https://www.ahlstrom-munksjo.com/globalassets/_about/pictures/everywhere-annual-general-meeting-852x568.png?width=800" v-else-->
          </div>
          <!--div class="col-md-3">
              <ul>
                  <li>
                      <router-link to="/certificate">Certificates</router-link>
                  </li>
                  <li>
                      <router-link to="/ddaysinstock">Days in Stock (Decor)</router-link>
                  </li>
                  <li>
                      <router-link to="/sdaysinstock">Days in Stock (Abrasive)</router-link>
                  </li>
                  <li>{{ $t('order.menu') }}</li>
              </ul>
          </div-->
        </div>

        <div class="row" v-else-if="extndBusinessArea == 'S' || extndBusinessArea == 'F'">
          <div class="col-md-9">
            <a href="https://www.ahlstrom.com/Media/articles/dedicoated-2023-is-over-/" target="_blank" v-if="extndBusinessArea == 'S'">
              <span style="font-size:14pt;color:#F05323">DediCOATED season 2 is over: discover our collective fresco!</span>
              <img src="@/assets/DediCOATED_collective_vision_3.jpg" width="900">
            </a>
            <img src="@/assets/001_1443_FAPP.jpg" width="820" v-if="extndBusinessArea == 'F'">
          </div>
          <div class="col-md-3">
            <div class="d-flex align-items-start flex-column" style="height: 450px">
              <div class="db-title">Welcome to CIP !</div>
              <div class="db-text mb-auto">
                By using our portal you<br>
                can easily
                <router-link to="/sopenorder">follow your<br> orders</router-link>
                ,
                organize
                <router-link to="/calloff/basket">call-off</router-link>
                ,<br>
                get product
                <router-link to="/certificate">quality<br> reports</router-link>
                ,
                <router-link to="/docs">download our<br> leaflets</router-link>
                and much more!
              </div>
              <div class="db-text db-inverse">
                <a href="staticHTML/AM_CIP_MANUAL_2023_01_10.pdf" target="_blank">Discover</a>
                now<br>
                all functionalities and<br>
                how to get customized<br>
                templates!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '../auth'
import * as cipLib from "@/cipLib";

export default {
  name: 'dashboard',
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    visBusinessAreas() {
      return auth.buildVisBusinessAreas(this.user, this)
    },
    businessArea() {
      return this.$store.state.businessArea
    },
    // extension to support FAPP (before removing it)
    extndBusinessArea() {
      if (this.businessArea == 'S') {
        let hasS = this.visBusinessAreas.find(ba => ba.code === 'S') != undefined
        return hasS ? 'S' : 'F'
      } else {
        return this.businessArea
      }
    },
  },
  methods: {
    isCipMunksjoUrl() {
      return cipLib.doCheckAmSeparation() && cipLib.isCipMunksjoUrl(location.href)
    },

  }
}
</script>

<style scoped lang="scss">
@import '../variables';

/*
.newsblock {
    background: white;
    padding: 0;
    margin-top: -1px
}

.newsblock .col-md-8 img, .newsblock .col-md-4 img {
    width: 100%;
}
*/

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  //color: $color-violet;
  //font-weight: bold;
}

a:hover {
  text-decoration: none;
}

.db-title {
  font-size: 26px;
  font-weight: bold;
  color: $color-violet;
  //text-align: center;
  padding: 10px;
  //margin-top: 10px;
  //margin-bottom: 10px;
}

.db-text {
  font-size: 20px;
  padding: 10px;
  //text-align: center;
}

.db-inverse {
  color: white;
  background-color: $color-violet;
}
</style>
