import * as validators from '@vuelidate/validators'
//import {createI18nMessage} from '@vuelidate/validators'

//const withI18nMessage = createI18nMessage({t})
//export const required = withI18nMessage(validators.required, {messagePath: () => 'validation.required'})
export const required = validators.required
export const minLength = validators.minLength
export const maxLength = validators.maxLength
export const email = validators.email


/*
import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max, confirmed, email } from 'vee-validate/dist/rules'
import i18n from './i18n'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
    ...required,
    message: (_, values) => i18n.t('validation.required', values)
})
extend('min', {
    ...min,
    message: (_, values) => i18n.t('validation.min', values)
})
extend('max', {
    ...max,
    message: (_, values) => i18n.t('validation.max', values)
})
extend('confirmed', {
    ...confirmed,
    message: (_, values) => i18n.t('validation.confirmed', values)
})
extend('email', {
    ...email,
    message: (_, values) => i18n.t('validation.email', values)
})
*/
