<template>
<div class="cip-content">
    <div class="card">
        <div class="card-header" style="text-transform: uppercase">
            {{ $t('user.settings.name') }}
        </div>
    </div>

    <ChangePassword :lockedSection="lockedSection" :loginName="user == null ? '' : user.loginName"/>
    <MyAccount :lockedSection="lockedSection" />
    <MyLanguage :lockedSection="lockedSection"/>
    <div class="card" id="aboconfig">
        <router-link to="/aboconfig" class="cip-card-header-btn btn btn-light" :disabled="lockedSection != null">
            <span class="float-left">{{ $t('user.settings.aboConf') }}</span>
        </router-link>
    </div>
    <div class="card" id="contact" v-if="businessArea == 'S' || businessArea == 'F'">
        <a href="mailto:contact.abrasives@ahlstrom-munksjo.com">Want to change settings? Ask for support? Contact us</a>
    </div>
    <div class="card" id="logout">
        <button type="button" class="btn btn-light btn-block cip-card-header-btn" @click="logout()" v-if="user != null" :disabled="lockedSection != null">
            <span class="float-left">{{ $t('user.logout') }}</span>
        </button>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import ChangePassword from './ChangePassword.vue'
import MyAccount from './MyAccount.vue'
import MyLanguage from './MyLanguage.vue'
import auth from '@/auth'

export default Vue.extend({
    name: 'mySettings',
    inject: ['eventBus'],
    components: {
        ChangePassword,
        MyAccount,
        MyLanguage,
    },
    data () {
        return {
            lockedSection: null,
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        businessArea() {
            return this.$store.state.businessArea
        },
    },
    methods: {
        logout() {
            auth.logout()
            .then(() => {
                this.eventBus.$emit('user-logged-out')
                this.$router.push(this.$route.query.redirect || '/login')
            })
        },
    }
})
</script>

<style lang="scss">

#aboconfig a span, #logout button span {
    padding-left: 20px;
}

#aboconfig a[disabled="disabled"] {
  pointer-events: none;
}

#aboconfig a[disabled="disabled"] span {
  font-weight: 400;
  color: #888888;
}

#contact a {
    padding-left: 30px;
    padding-top: 6px;
    padding-bottom: 6px;
    //display: inline;
}
</style>
