<template>
    <div class="card">
        <div class="card-header slim-fit">
            <button type="button" class="btn cip-card-header-btn btn-light btn-block collapsed" data-toggle="collapse"
                    data-target="#mylng"
                    :disabled="!isSectionToggleable()" @click="toggleSection($options.name)">
                <span class="cip-card-header-btn-text">{{ $t('user.settings.myLang') }}</span>
                <div class="float-right">
                    <font-awesome-icon icon="chevron-down" class="rotating-chevron"/>
                </div>
            </button>
        </div>
        <div class="card-body collapse" id="mylng">
            <form @submit.prevent="saveLanguage"> <!-- not working in IE :disabled="isSectionToggleable()"-->

                <div class="form-group form-row">
                    <!--label for="language" class="col-sm-2 col-form-label">Language</label-->
                    <div class="col-sm-3">
                        <select name="language"
                                v-model="v$.userLangCode.$model"
                                class="form-control" :class="{ 'invalid': v$.userLangCode.$error }"
                                :disabled="!isSectionToggleable()">
                            <option v-for="lang in languages" :value="lang.code" :key="lang.code">
                                {{ lang.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <span class="form-text error-msg"
                              v-if="v$.userLangCode.$error">{{ v$.userLangCode.$errors[0].$message }}</span>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-sm-3">
                        <button type="submit" class="btn btn-primary" :disabled="v$.$invalid">
                            {{ $t('user.changeLang') }}
                        </button>
                    </div>
                    <div class="col-sm-9">
                        <span :class="{'error-msg': isErrorMsg}">{{ msg }}</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import cipAxios from '@/cipAxios'
import useVuelidate from "@vuelidate/core"
import {required} from "@/validation"

export default Vue.extend({
    name: 'MyLanguage',
    props: ['lockedSection'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            userLangCode: null,
            languages: [],
            msg: "",
            isErrorMsg: false,
            isOpen: false,
        }
    },
    validations() {
        return {
            userLangCode: {required}
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    watch: {
        user() {
            //console.log('watching user change in MyLanguage')
            this.initFormData()
        }
    },
    created: function () {
        // TODO why is this not triggered by watch-user?
        this.initFormData()
    },
    methods: {
        initFormData() {
            this.userLangCode = this.user == null ? null : this.user.langCode
            this.setLanguages()
        },
        isSectionToggleable() {
            return this.lockedSection == null || this.lockedSection == this.$options.name
        },
        toggleSection(section) {
            this.isOpen = !this.isOpen
            if (this.isOpen) {
                this.$parent.lockedSection = section
            } else {
                this.$parent.lockedSection = null
            }
        },
        getLanguages() {
            return cipAxios.get('rest/languages')
                .then(response => response.data)
        },
        setLanguages() {
            this.getLanguages()
                .then(languages => {
                    this.languages = languages
                })
        },
        async saveLanguage() {
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                this.clearMsg()
                cipAxios.post('rest/myaccount/' + this.user.userId + '/language/' + this.userLangCode)
                    .then(() => {
                        this.$emit('user-account-updated')
                        this.setInfoMsg("Language changed")
                    })
                    .catch(errorMsg => {
                        this.setErrorMsg(errorMsg)
                    })
            }
        },
        clearMsg() {
            this.msg = ""
            this.isErrorMsg = false
        },
        setInfoMsg(msg) {
            this.msg = msg
            this.isErrorMsg = false
        },
        setErrorMsg(msg) {
            this.msg = msg
            this.isErrorMsg = true
        },
    }
})
</script>

<style lang="scss">
</style>
