import moment from 'moment'

function isLocalDate(localDate) {
    return (localDate && (localDate instanceof Array) && localDate.length >= 3)
}

function createDate(localDate) {
    if (!(isLocalDate(localDate)))
        return null
    else {
        // convert month to monthIndex as used by Date constructor
        const cloneLocalDate = [...localDate]
        cloneLocalDate[1] -= 1
        return new Date(...cloneLocalDate)
    }
}

function createLocalDate(date) {
    if (date == null)
        return null
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()]    // monthIndex to month
}

function createLocalDateTime(date) {
    if (date == null)
        return null
    return [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()]
}

function isInPeriod(date, periodFrom, periodTo) {
    let md = null
    if (date instanceof moment)
        md = date
    else if (isLocalDate(date))
        md = moment(createDate(date))
    else
        md = moment(date)
    
    if (isLocalDate(periodFrom))
        periodFrom = createDate(periodFrom)
    if (periodFrom != null && md.isBefore(periodFrom))
        return false

    if (isLocalDate(periodTo))
        periodTo = createDate(periodTo)
    if (periodTo != null && md.isAfter(periodTo))
        return false
    return true
}

function cipFormatDateFilter(localDate) {
    let vd = createDate(localDate)
    if (vd == null)
        return ''
    else
        return moment(vd).format('DD.MM.YYYY') // TODO localize
}
  
function cipFormatDateTimeFilter(localDateTime) {
    let vd = createDate(localDateTime)
    if (vd == null)
        return ''
    else
        return moment(vd).format('DD.MM.YYYY HH:mm:ss'); // TODO localize
}
  

export { createDate, createLocalDate, createLocalDateTime, isInPeriod, cipFormatDateFilter, cipFormatDateTimeFilter }