<template>
<div class="cip-content">
    <div class="full-frame-card">
        <iframe id="iframe" :src="src" ref="iframe"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import auth from '../../auth'
import { getUrl } from '../../cipLib'

export default {
    name: 't-non-produced-order',
    mixins: [CipIFrame],
    data () {
        return {
            src: '',
        }
    },

    beforeRouteUpdate() {
        this.$refs.iframe.contentWindow.location.replace(this.src)
    },

    methods: {
        setSrc(user) {
        if (auth.hasPriv(user, 'T_Non_Prod_Order_CG'))
            this.src = getUrl('Main?NAVPOINT=T_Non_Prod_Order_CG&MODULE=REP&REPNAME=T_Non_Prod_Order_CG&ACTION=PARAMEXPERT')
        else
            this.src = getUrl('Main?NAVPOINT=T_Non_Prod_Order_C_M&MODULE=REP&REPNAME=T_Non_Prod_Order_C_M&ACTION=PARAMEXPERT')
        }
    }
}
</script>
