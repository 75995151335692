<template>
<div class="cip-content">
    <div class="full-frame-card">
        <iframe id="iframe" :src="src" ref="iframe"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import { getUrl } from '../../cipLib'

export default {
    name: 'invoice',
    mixins: [CipIFrame],
    data () {
        return {
            src: '',
        }
    },

    beforeRouteUpdate() {
        this.$refs.iframe.contentWindow.location.replace(this.src)
    },

    methods: {
        setSrc() {
            this.src = getUrl('Main?NAVPOINT=Invoice_H&MODULE=REP&REPNAME=Invoice_H')
        }
    }
}
</script>
