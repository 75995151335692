<template>
    <div class="card">
        <div class="card-header slim-fit">
            <button type="button" class="btn cip-card-header-btn btn-light btn-block collapsed" data-toggle="collapse"
                    data-target="#chgpwd"
                    :disabled="!isSectionToggleable()" @click="toggleSection($options.name)" ref="tglbtn">
                <span class="cip-card-header-btn-text">{{ $t('user.settings.changePwd') }}</span>
                <div class="float-right">
                    <font-awesome-icon icon="chevron-down" class="rotating-chevron"/>
                </div>
            </button>
        </div>
        <div class="card-body collapse" id="chgpwd">
            <form @submit.prevent="changePwd"> <!-- not working in IE: :disabled="isSectionToggleable()" -->

                <div class="form-group form-row">
                    <label for="currPassword" class="col-sm-3 col-form-label">
                        {{ $t('user.settings.currPwd') }}
                    </label>
                    <div class="col-sm-4">
                        <input id="currPassword" name="currPassword" ref="currPassword" type="password"
                               v-model="v$.currPassword.$model"
                               class="form-control" :class="{ 'invalid': v$.currPassword.$error }"
                               autocomplete="off">
                    </div>
                    <div class="col-sm-5">
                        <span class="form-text error-msg" v-if="v$.currPassword.$error">
                            {{ v$.currPassword.$errors[0].$message }}
                        </span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="newPassword" class="col-sm-3 col-form-label">
                        {{ $t('user.settings.newPwd') }}
                    </label>
                    <div class="col-sm-4">
                        <input id="newPassword" name="newPassword" ref="newPassword" type="password"
                               v-model="v$.newPassword.$model"
                               class="form-control" :class="{ 'invalid': v$.newPassword.$error }">
                    </div>
                    <div class="col-sm-5">
                        <span class="form-text error-msg" v-if="v$.newPassword.$error">
                            {{ v$.newPassword.$errors[0].$message }}
                        </span>
                    </div>
                </div>

                <div class="form-group form-row">
                    <label for="newPassword2" class="col-sm-3 col-form-label">
                        {{ $t('user.settings.newPwd2') }}
                    </label>
                    <div class="col-sm-4">
                        <input id="newPassword2" name="newPassword2" type="password"
                               v-model="v$.newPassword2.$model"
                               class="form-control" :class="{ 'invalid': v$.newPassword2.$error }">
                    </div>
                    <div class="col-sm-5">
                        <span class="form-text error-msg" v-if="v$.newPassword2.$error">
                            {{ v$.newPassword2.$errors[0].$message }}
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <button type="submit" class="btn btn-primary" :disabled="v$.$invalid">
                            {{ $t('user.settings.changePwd') }}
                        </button>
                    </div>
                    <div class="col-sm-9">
                        <span :class="{'error-msg': isErrorMsg}">{{ msg }}</span>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import auth from '@/auth'
import useVuelidate from "@vuelidate/core"
import {helpers} from "@vuelidate/validators"
import {required, maxLength, minLength} from "@/validation"

export default Vue.extend({
    name: 'changePassword',
    props: ['loginName', 'lockedSection', 'initiallyOpen'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            currPassword: "",
            newPassword: "",
            newPassword2: "",
            msg: "",
            isErrorMsg: false,
            isOpen: false,
        }
    },
    validations() {
        const sameAsNewPassword = (newPassword2) => {
            return newPassword2 === this.newPassword;
        };
        return {
            currPassword: {required},
            newPassword: {required, minLength: minLength(6), maxLength: maxLength(20)},
            newPassword2: {
                required,
                sameAsNewPassword: helpers.withMessage('Passwords don\'t match', sameAsNewPassword)
            },
        }
    },
    mounted: function () {
        if (this.initiallyOpen) {
            this.$refs.tglbtn.click()
        }
    },
    methods: {
        isSectionToggleable() {
            return this.lockedSection == null || this.lockedSection == this.$options.name
        },

        toggleSection(section) {
            this.isOpen = !this.isOpen
            if (this.isOpen) {
                this.$parent.lockedSection = section
            } else {
                this.$parent.lockedSection = null
                this.resetPasswordForm()
            }
        },

        async changePwd() {
            const isFormCorrect = await this.v$.$validate()
            if (isFormCorrect) {
                this.clearMsg()
                auth.changePwd(this.loginName, this.currPassword, this.newPassword, this.newPassword2)
                    .then(() => {
                        this.$emit('password-changed', this.newPassword)
                        this.resetPasswordForm()
                        this.setInfoMsg("Password changed")
                    })
                    .catch((errorMsg) => {
                        this.setErrorMsg(errorMsg)
                    })
            }
        },

        clearMsg() {
            this.msg = ""
            this.isErrorMsg = false
        },
        setInfoMsg(msg) {
            this.msg = msg
            this.isErrorMsg = false
        },
        setErrorMsg(msg) {
            this.msg = msg
            this.isErrorMsg = true
        },

        resetPasswordForm() {
            this.currPassword = ''
            this.newPassword = ''
            this.newPassword2 = ''
            this.clearMsg()
            this.v$.$reset()
        }
    }
})
</script>

<style lang="scss">
</style>
