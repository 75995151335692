import { render, staticRenderFns } from "./CipMenu.vue?vue&type=template&id=8a875848&scoped=true&"
import script from "./CipMenu.vue?vue&type=script&lang=js&"
export * from "./CipMenu.vue?vue&type=script&lang=js&"
import style0 from "./CipMenu.vue?vue&type=style&index=0&id=8a875848&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a875848",
  null
  
)

export default component.exports