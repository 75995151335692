<template>
  <div id="cip">
    <cip-header></cip-header>

    <router-view></router-view>

  </div>
</template>

<script>
import Vue from 'vue'
import auth from './auth.js'
import * as cipLib from './cipLib'
import CipHeader from './components/CipHeader'

let eventBus = new Vue()

export default {
  name: 'app',
  components: {
    CipHeader,
  },
  provide: {
    eventBus,
  },
  data() {
    return {}
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user
      },
      set(user) {
        this.$store.commit('setUser', user)
      },
    },
  },
  created: function () {
    eventBus.$on('user-account-updated', () => {
      this.setUser()
    })
    eventBus.$on('user-logged-in', () => {
      this.setUser()
    })
    eventBus.$on('user-logged-out', () => {
      this.user = null
    })
  },
  mounted: function () {
    this.setUser()
  },
  methods: {
    setUser() {
      return auth.getLoggedInUser()
        .then(user => {
          this.user = user
        })
        .then(() => {
          if (this.user == null) {
            if (this.$route.path != '/login') {
              //console.log('App.setUser: forwarding to login')
              this.$i18n.locale = 'en'
              this.$router.push('/login')
            }
          } else {
            this.$i18n.locale = this.user.langCode.toLowerCase()
            //console.log('setting locale to ' + this.$i18n.locale)
          }
        })
        .then(() => {
          if (cipLib.doCheckAmSeparation() && this.user != null) {
            let authForDecorTPP = auth.isUserAuthorizedForDecorTPP(this.user)
            //console.log("checking Ahlstrom vs Munksjö (authForDecorTPP = " + authForDecorTPP + ", location.href = " + location.href + ")")
            //console.log(" with: cipMunksjoUrl = " + cipLib.getCipMunksjoUrl() + ", cipAhlstromUrl = " + cipLib.getCipAhlstromUrl() + ")")

            if (authForDecorTPP && cipLib.isCipAhlstromUrl(location.href)) {
              //console.log("logging-out from Ahlstrom")
              auth.logout()
                .then(() => {
                  //console.log("forwarding to Munksjö")
                  location.href = cipLib.getCipMunksjoUrl()
                })
            } else if (!authForDecorTPP && cipLib.isCipMunksjoUrl(location.href)) {
              //console.log("logging-out from Munksjö")
              auth.logout()
                .then(() => {
                  //console.log("forwarding to Ahlstrom")
                  location.href = cipLib.getCipAhlstromUrl()
                })
            }
          }
        })
    },
  }
}
</script>

<style lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'variables';

body {
  font-family: "hurme", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: $color-dark-grey;
}

#cip {
  min-height: 100vh;
  @extend .d-flex;
  @extend .flex-column;
  background-color: $color-light-grey;
}

.cip-content {
  //padding-top: 1px;
  //padding-bottom: 1px;
  //background-color: $color-light-grey;
}

.cip-content-header-card {
  @extend .card;
}

.full-frame-card {
  @extend .card;
  min-width: 99vw;
}

div .card {
  @extend .container;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  @extend .shadow-sm;
}

div .card-header.slim-fit {
  padding: 0px;
  border: 0px;
}

.cip-card-header-btn {
  border: 0px;
}

.cip-card-header-btn-text {
  @extend .float-left;
  padding-left: 20px;
}

.card-header .rotating-chevron {
  transition: .3s transform ease-in-out;
}

.card-header .collapsed .rotating-chevron {
  transform: rotate(90deg);
}

.btn-close {
  @extend .btn;
  @extend .btn-light;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.error-msg {
  @extend .text-danger;
}

form .invalid {
  border: solid 1px theme-color("danger");
}

iframe {
  border-width: 0px;
  width: 100%;
  // height: 600px;
  height: calc(100vh - 97px - 32px); // - header - full-frame-card TODO reactive
}

.btn-transparent {
  background: transparent;
}

</style>
