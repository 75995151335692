<template>
<div id="news" class="cip-content">
    <div class="card">
        <iframe id="iframe" :src="src"></iframe>
    </div>
</div>
</template>

<script>
import CipIFrame from '../CipIFrame'
import { getUrl } from '../../cipLib'

export default {
  name: 'last-loading',
  mixins: [CipIFrame],
  data () {
    return {
      src: '',
    }
  },

  methods: {
    setSrc() {
      this.src = getUrl('Main?NAVPOINT=Mill_File_Loading&MODULE=REP&REPNAME=Mill_File_Loading')
    }
  }
}
</script>

<style scoped lang="scss">
div .card {
    max-width: 1200px;
}
</style>
